<template>
  <div class="card mt-4">
		<div class="card-header">
			<div class=" text-center">
				<div><h3>INCOME & EXPENSE</h3></div>
				<!-- <div>
					<date-range-picker
    v-model="dateRange"
    @update="dateChange"
	:locale-data="{ format: 'dd-mm-yyyy' }"
  ></date-range-picker>
			</div> -->
			<div class="row">
					<div class="col-md-4"></div>
					<div class="col-md-4 text-center">
						<date-range-picker
    v-model="dateRange"
    @update="dateChange"
	:locale-data="{ format: 'dd-mm-yyyy' }"
  ></date-range-picker>
					</div>
					<div class="col-md-4 text-right">
  <button  type="button" class="btn btn-venuecolor"><i class="mdi mdi-export"></i>Export</button>

			</div>
			</div>
		</div>
		
					</div>
                    
					<div class="card-body">
						<table class="table table-bordered table-sm">
							<thead class="thead-light">
								<tr >
									<td colspan="2" class="text-center border-right w-50">Expense</td>
									<td colspan="2" class="text-center">Income</td>
								</tr>
							</thead>
								<tr>
							
									<td class="text-left">Particular</td>
									<td class="border-right text-left">Amount</td>
									
									<td class="text-left">Particular</td>
									<td class="text-left">Amount</td>
								</tr>
								<tbody style="    height: 300px; ">
								
								<tr>
									<!-- Expense -->
									<td class="text-center wc-5" style="vertical-align: top;" v-if="expense_list.length < 0 " colspan="3">
										No Data Found
									</td> 
									<td class="text-left w-40" style="vertical-align: top;">
										<span  v-if="expense_list.length  <= 0 ">
									No Data Found
									</span>
                                        <span v-else v-for="(exp ,i ) in expense_list" :key="i">{{ exp.description }}<br></span>
										<span  v-if="expense_list.length <= 0 ">
										<div class="text-success mt-3" v-if="message!=''" >{{ message }}<br></div>
										</span>
									</td>
									<td class="border-right text-right wc-5" style="vertical-align: top;">
                                        <span v-for="(exp ,i ) in expense_list" :key="i">
											<span v-html="currency_check(numberWithCommas(exp.price))"></span>
											<br></span>
                                        <div class="text-success  mt-3" v-if="message!=''" >
											<span v-html="currency_check(numberWithCommas(amount))"></span>	
											<br></div>
									</td>

									<!-- Income -->

									<!-- <td class="text-center wc-5" style="vertical-align: top;" >
										<span v-for="(da ,i ) in income_list" :key="i">{{ date_format(da.date) }}<br></span>
										

									</td> -->
								
									
									<td class="text-left w-40" style="vertical-align: top;">
									<span  v-if="income_list.length <= 0 ">
									No Data Found
									</span>
                                        <span v-else v-for="(da ,i ) in income_list" :key="i">
											<b v-if="da.group.other.amount==0">{{ da.group.group }}</b>
											<div class="ml-2"  v-if="da.group.other.amount!=0">{{ da.group.group }}</div>
											<div class="ml-2"  v-if="da.group.Base_price.amount!=0">Base Price</div>
											<div class="ml-2"  v-if="da.group.Addon.amount!=0">Addon</div>
											<div class="ml-2"  v-if="da.group.Security_deposit.amount!=0">Security Deposit</div> 
										</span>
									<span v-if="income_list.length < 0 ">
										<div class="text-danger mt-3" v-if="message1!=''" >{{ message1 }}<br></div>
									</span>
										
									</td>
									<td class=" text-right wc-5" style="vertical-align: top;">
                                        <span v-for="(da ,i ) in income_list" :key="i">
											<b v-if="da.group.other.amount==0"><br></b>
											<div class="ml-2"  v-if="da.group.other.amount!=0"><span v-html="currency_check(numberWithCommas(da.group.other.amount))"></span></div>
											<div class="ml-2" v-if="da.group.Base_price.amount!=0"><span v-html="currency_check(numberWithCommas(da.group.Base_price.amount))"></span></div>
											<div class="ml-2" v-if="da.group.Addon.amount!=0"><span v-html="currency_check(numberWithCommas(da.group.Addon.amount))"></span></div>
											<div class="ml-2" v-if="da.group.Security_deposit.amount!=0"><span v-html="currency_check(numberWithCommas(da.group.Security_deposit.amount))"></span></div> 
										</span>
								
										
                                        <div class="text-danger  mt-3 " v-if="message1!=''" >
											<span v-html="currency_check(numberWithCommas(amount1))"></span>
											<br></div>
									</td>
								</tr>
							</tbody>
								<tfoot class="thead-light">
									<tr>
										<th class="text-center">Total</th>
										<th  class="text-right border-right" >
											<span v-html="currency_check(numberWithCommas(income_sum))"></span>
											</th>

										<th class="text-center">Total</th>
										<th  class="text-right">
											<span v-html="currency_check(numberWithCommas(expense_sum))"></span>
											</th>

										
									</tr>
								</tfoot>
							</table>
						
					</div>
				</div>
</template>

<script>
import axios from 'axios'
import helpers from '../../../mixins/helpers'; 
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
	mixins: [helpers],
data:()=>({
income_list:[],
expense_list:[],
booking_prices:[],
message:"",
message1:"",
amount:"",
amount1:"",
curncy:"",
income_sum:0,
expense_sum:0,
dateRange: {
	startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
}),
components: {DateRangePicker},
methods:
{
    income_expense_list() {
      axios.post("/api/income_expense_report",{month:this.dateRange}).then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.income_list=resp.data.array_income;
            this.expense_list=resp.data.expense_list;
            this.booking_prices=resp.data.booking_price;
           
            
            if(resp.data.income_sum>resp.data.expense_sum)
            {
                this.income_sum=resp.data.income_sum;
                this.expense_sum=resp.data.income_sum;
                var amt = resp.data.income_sum-resp.data.expense_sum;
                this.message ="Profit  " ;
                this.message1 ="";
                this.amount=amt;
            }
            else
            {
                this.income_sum=resp.data.expense_sum;
                this.expense_sum=resp.data.expense_sum;
                var amt1 = resp.data.expense_sum-resp.data.income_sum;
                this.message1 ="Loss  " ;
                this.amount1=amt1;
                this.message ="";
            }
          }
        }
      });
    },
	currency_check(amt) {
    return '<i class="mdi '+this.curncy+'"></i>'+amt;
			},
			getProfile() {
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= resp.data.users.currencys;
					}
				});
			},
			//dateFormat (classes, date) {
       /*  if (!classes.disabled) {
          classes.disabled = date.getTime() < new Date()
        }
        return classes */
      //}
			dateChange()
			{
				this.income_expense_list();
			},
	numberWithCommas1(x) {
              
		var num = x ? x : 0;
		return num.toLocaleString('en-IN')
	},
},
mounted()
{
    this.getProfile();
    this.income_expense_list();
}
}
</script>

<style>

.wc-5
{
	width: 150px;
}
.daterangepicker
{

}
.daterangepicker.show-calendar .ranges {
    width: 138px !important;
}
.w-40
{
	width: 40%;
}
</style>